import { useToast } from 'vue-toastification';
import router from '@/router';
import { useAuthStore } from '@/stores/auth';

const toast = useToast();

export const fetchWrapper = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE'),
};

function request(method) {
  return async (url, body, withFiles) => {
    const requestOptions = {
      method,
      credentials: 'include',
      headers: {
        Authorization: `Bearer ${window.localStorage.getItem('token')}`,
      },
    };
    if (body) {
      if (!withFiles) requestOptions.headers['Content-Type'] = 'application/json';
      requestOptions.body = !withFiles ? JSON.stringify(body) : body;
    }

    return fetch(url, requestOptions).then(handleResponse);
  };
}

function handleResponse(response) {
  const authStore = useAuthStore();

  return response.text().then(async (text) => {
    const data = text && JSON.parse(text.toString());

    if (!response.ok) {
      if ([401, 403].includes(response.status)) {
        await router.push(data.redirectPath);
      }
      let messages = null;
      if (data?.messages) messages = Object.values(data.messages).join('\n');

      let msg = messages || data?.message || response.statusText;

      if (msg === 'Unauthorized') {
        msg = 'Acesso Negado';
      }

      if (data.forceLogout || data.logoutByLoa) {
        toast.error(msg.toString());
        authStore.logoutCallback();
        setTimeout(async () => {
          await router.push('/');
        }, 3000);
      } else {
        toast.error(msg.toString());
        console.error(msg);
        return data;
      }
    }

    return data;
  });
}
